import React, { useState } from 'react';
import { styled } from 'react-free-style';
import Creatable from 'react-select/creatable';
import Select, { components } from 'react-select';
import { icons } from '../../styles/icons';
import relationshipTypes from '../../support/items/sales-financing';
import { optionIsValidCreation } from '../../support/creatableCompanyFilter';

const selectFieldStyle = ({ indicatorsContainerStyles, controlStyles = {} }) => ({
  control: provided => ({
    ...provided,
    borderRadius: 0,
    borderColor: 'rgba(224, 224, 224, 1)',
    cursor: 'pointer',
    backgroundColor: '',
    color: '',
    ...controlStyles,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    ...indicatorsContainerStyles,
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: provided => ({
    ...provided,
    color: '',
  }),
});

const CustomInput = ({ testId, ...props }) => <components.Input {...props} data-testid={testId} />;

const SalesFinancingItem = props => {
  const {
    styles,
    key,
    company,
    onCompanyChanged,
    onSearchCompanies,
    relationshipType,
    onRelationshipTypeChanged,
    onDelete,
  } = props;
  const [companySearchResults, setCompanySearchResults] = useState([]);
  const isNew = company === '' && relationshipType === '';
  return (
    <div className={styles.container} key={key}>
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        {/* Relationship Type */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 0.4, marginRight: 10 }}>
          <Select
            placeholder="Add Company"
            value={relationshipType && { value: relationshipType, label: relationshipType }}
            options={Object.keys(relationshipTypes).map(s => {
              return { value: s, label: s };
            })}
            style={{ border: `1px solid rgba(224, 224, 224, ${isNew ? '1' : '0'})` }}
            onChange={({ value }) => {
              onRelationshipTypeChanged && onRelationshipTypeChanged(value);
              if (company && company._id) {
                onCompanyChanged({ _id: company.name, name: company.name });
              }
              setCompanySearchResults([]);
            }}
            onBlurComplete={true}
            styles={selectFieldStyle({
              indicatorsContainerStyles: { display: 'none' },
              controlStyles: {
                border: `1px solid rgba(224, 224, 224, ${isNew ? '1' : '0'})`,
                '&:hover': {
                  border: '1px solid rgb(87, 140, 247)',
                },
              },
            })}
            components={{
              Input: props => (
                <CustomInput {...props} testId={`addCompany-input${relationshipType ? `-${relationshipType}` : ''}`} />
              ),
            }}
          />
        </div>

        {/* Company */}
        <div style={{ display: 'flex', flexDirection: 'column', flex: 0.6, marginLeft: 10, marginRight: 10 }}>
          {relationshipType && (
            <Creatable
              options={companySearchResults.map(company => {
                return { label: company.name, value: company._id };
              })}
              onChange={({ value, label }) => {
                onCompanyChanged && onCompanyChanged({ _id: value, name: label });
                setCompanySearchResults([]);
              }}
              placeholder="Company Name"
              onInputChange={value => {
                value = value?.trim();
                if (!value) {
                  return setCompanySearchResults([]);
                }
                const profile = relationshipTypes[relationshipType];
                onSearchCompanies &&
                  onSearchCompanies(value, profile).then(companies => {
                    setCompanySearchResults(companies);
                  });
              }}
              value={
                company
                  ? {
                      value: company._id ? company._id : company,
                      label: company.name ? company.name : company,
                    }
                  : null
              }
              styles={selectFieldStyle({
                indicatorsContainerStyles: { display: 'none' },
                controlStyles: {
                  border: `1px solid rgba(224, 224, 224, ${isNew ? '1' : '0'})`,
                  '&:hover': {
                    border: '1px solid rgb(87, 140, 247)',
                  },
                },
              })}
              isDisabled={!relationshipType}
              onBlurComplete={true}
              isValidNewOption={(inputValue, _values, options) => optionIsValidCreation(inputValue, options)}
              components={{
                Input: props => (
                  <CustomInput {...props} testId={`companyName-input${company?.name ? `-${company?.name}` : ''}`} />
                ),
              }}
            />
          )}
        </div>
      </div>

      {/* Delete  */}
      {onDelete && (
        <div
          className={styles.delete}
          onClick={() => {
            onDelete && onDelete();
          }}
          key={`${key}-delete`}
          data-testid="salesFinance-deleteButton"
        >
          <i className={styles.deleteIcon} />
        </div>
      )}
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    fontWeight: 300,
    fontSize: '12px',
    paddingTop: 5,
  },
  input: {
    position: 'relative',
    display: 'flex',
    padding: 7,
    fontWeight: 300,
    fontSize: 12,
    border: '1px solid rgba(0, 0, 0, 0)',
    width: '95%',
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textArea: {
    border: '1px solid rgba(0, 0, 0, 0)',
    '&:hover': {
      border: '1px solid rgb(87, 140, 247)',
    },
    paddingTop: 7,
    resize: 'none',
    fontWeight: 300,
    marginRight: 10,
  },
  delete: {
    cursor: 'pointer',
    padding: '10px 0',
    '&+&': {
      marginLeft: '10px',
    },
  },
  hidden: {
    color: 'rgba(0,0,0,0)',
  },
  deleteIcon: icons.smallCrossBlack,
});

export default withStyles(SalesFinancingItem);
