import React from 'react';
import { VerificationIcon } from '@united-talent-agency/components';

const Verification = ({ data = {}, containerStyles = '' }) => {
  const info = {
    id: data?.verifiedBy?._id,
    name: `${data?.verifiedBy?.first_name} ${data?.verifiedBy?.last_name}`,
    on: data?.verifiedOn,
  };

  return (
    <div className={containerStyles}>
      <VerificationIcon verifiedBy={info?.name} verifiedOn={info?.on} identifier={info?.id} includeExplanation />
    </div>
  );
};

export default Verification;
