import React from 'react';
import { styled, helpers } from 'react-free-style';

import { elements, icons } from '@united-talent-agency/julius-frontend-components';

const NoteButtons = props => {
  const { styles, note, onEditItem, onDeleteItem, onArchiveNote } = props;
  return (
    <div className={styles.fixedEditButtons}>
      <button id='noteEditBtn' type="button" className={styles.button} onClick={() => onEditItem(note)}>
        <i className={styles.editIcon} />
      </button>
      <button id='noteArchiveBtn' className={styles.button} onClick={() => onArchiveNote(note)}>
        <i className={note.archived ? 'fa fa-undo' : 'fa fa-archive'} />
      </button>
      <button id='noteDeleteBtn' type="button" className={styles.button} onClick={() => onDeleteItem(note)}>
        <i className={styles.deleteIcon} />
      </button>
    </div>
  );
};

const withStyles = styled({
  fixedEditButtons: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
  },
  button: helpers.merge(elements.reset, elements.actionable, {
    padding: 10,
    lineHeight: 0,
  }),
  editIcon: icons.pencil,
  deleteIcon: icons.trashcan,
});

export default withStyles(NoteButtons);
