import React, { Fragment, useState } from 'react';
import classnames from 'classnames';
import { styled } from 'react-free-style';
import { Col, Row } from 'reactstrap';
import Select, { components } from 'react-select';
import Creatable from 'react-select/creatable';

import { colors, EditButton, buttonTypes } from '@united-talent-agency/julius-frontend-components';

import NewModal from './new-modal';

import { groupRoles } from '../../../../../api/groups';

import { icons } from '../../../../../styles/icons';
import { optionIsValidCreation } from '../../../../../support/creatableCompanyFilter';
import { iconStyles, getUserTypeStyles } from '../../../../../support/user-type-util';
import cypressTags from '../../../../../support/cypressTags';
import { searchClient } from '../../../../../support/algolia/algolia-search-client';
import { getCompaniesIndex, getPeopleIndex } from '../../../../../support/algolia/get-index';

const selectFieldStyle = ({ indicatorsContainerStyles, controlStyles = {} }) => ({
  control: provided => ({
    ...provided,
    borderRadius: 0,
    borderColor: 'rgba(224, 224, 224, 1)',
    cursor: 'pointer',
    backgroundColor: '',
    color: '',
    ...controlStyles,
  }),
  indicatorsContainer: provided => ({
    ...provided,
    ...indicatorsContainerStyles,
  }),
  option: provided => ({
    ...provided,
    cursor: 'pointer',
  }),
  singleValue: provided => ({
    ...provided,
    color: '',
  }),
});

const ExecutiveControl = ({
  executives = [],
  styles,
  title,
  searchBy,
  hideExecType,
  isDirty,
  onChanged,
  onCancel,
  onSave,
  onDelete,
}) => {
  const [companies, setCompanies] = useState([]);
  const [companiesForModal, setCompaniesForModal] = useState([]);
  const [people, setPeople] = useState([]);
  const [peopleForModal, setPeopleForModal] = useState([]);
  const [companyModalOptions, setCompanyModalOptions] = useState({
    name: '',
    executive: '',
    index: '',
  });
  const [displayCompanyModal, setDisplayCompanyModal] = useState(false);
  const [executiveModalOptions, setExecutiveModalOptions] = useState({
    name: '',
    executive: '',
    index: '',
  });
  const [displayExecutiveModal, setDisplayExecutiveModal] = useState(false);

  const mapPeople = people => {
    return people.map(person => ({ value: person, label: person.name, _id: person._id }));
  };

  const fetchPeople = (name, executive, index) => {
    if (name === '') {
      setPeople([]);
      return;
    }
    let personRoleType = '';

    //more project role types can be added here
    switch (executive.executiveType) {
      case 'Contract Administrator':
        personRoleType = 'contractAdministrator';
        break;
      default:
        personRoleType = null;
        break;
    }
    const peopleIndex = getPeopleIndex();
    const indexSetting = {
      hitsPerPage: 10,
      page: 0,
    };
    if (personRoleType) {
      indexSetting.filters = `projectRoles.k:${personRoleType}`;
    }
    if (!displayExecutiveModal) setExecutiveModalOptions({ ...executiveModalOptions, name, executive, index });
    searchClient(peopleIndex, name, indexSetting).then(({ hits = [] }) => {
      let foundPeople = mapPeople(hits);
      setPeople(foundPeople);
      if (!displayExecutiveModal) setPeopleForModal(foundPeople);
    });
  };

  const fetchCompanies = (name, executive, index) => {
    console.log('fetchCompanies');
    name = name.trim();
    if (name === '') {
      setCompanies([]);
      return;
    }
    const role = groupRoles[searchBy];
    const companyIndex = getCompaniesIndex();
    const companyRole = role?.split('.')[1].trim();
    const indexSetting = {
      filters: `(roles:${companyRole})`,
      hitsPerPage: 10,
      page: 0,
    };
    if (!displayCompanyModal) setCompanyModalOptions({ ...companyModalOptions, name, executive, index });
    searchClient(companyIndex, name, indexSetting).then(({ hits = [] }) => {
      let data = hits;
      setCompanies(data.map(company => ({ value: company, label: company.name })));
      if (!displayCompanyModal) setCompaniesForModal(data.map(company => ({ value: company, label: company.name })));
    });
  };

  const headers = hideExecType
    ? ['Company Name', 'Executive Name']
    : ['Company Name', 'Executive Type', 'Executive Name'];

  const execTypes = ['Literary', 'Talent', 'Contract Administrator']
    .map(type => ({ value: type, label: type }))
    .concat({ value: '', label: 'None' });

  const errors = (executives || []).reduce((obj, cur, index) => {
    const curErrors = [];
    if (!cur.company) {
      curErrors.push('Company is Required');
    }
    if (cur.executiveType && !cur.executive) {
      curErrors.push('Executive is Required');
    }
    if (curErrors.length > 0) {
      obj[index] = curErrors;
    }
    return obj;
  }, {});
  const hasNew = (executives || []).some(exec => {
    return !exec.company && !exec.executiveType && !exec.executive;
  });
  const execsWithNew = (executives || []).concat(hasNew ? [] : {});

  return (
    <div className={styles.pane}>
      <div className={classnames(styles.titleHeader, isDirty && styles.headerEdit)}>
        <NewModal
          isModalOpen={displayCompanyModal}
          onModalClose={() => setDisplayCompanyModal(false)}
          name={companyModalOptions.name}
          label="company"
          records={companiesForModal}
          onModalBtnPress={value => {
            companyModalOptions.executive.company = value;
            onChanged && onChanged(companyModalOptions.executive, companyModalOptions.index);
            setDisplayCompanyModal(false);
          }}
        />
        <NewModal
          isModalOpen={displayExecutiveModal}
          onModalClose={() => setDisplayExecutiveModal(false)}
          name={executiveModalOptions.name}
          label="executive"
          records={peopleForModal}
          onModalBtnPress={value => {
            executiveModalOptions.executive.executive = value;
            onChanged && onChanged(executiveModalOptions.executive, executiveModalOptions.index);
            setDisplayExecutiveModal(false);
          }}
        />
        <h3 className={styles.title}>{title}</h3>
        {isDirty && (
          <EditButton
            className={styles.icon}
            type={buttonTypes.cancel}
            onClick={() => {
              onCancel && onCancel();
            }}
          />
        )}
        {isDirty && Object.keys(errors).length === 0 && (
          <EditButton
            className={styles.icon}
            type={buttonTypes.save}
            onClick={() => {
              onSave && onSave(executives);
            }}
          />
        )}
      </div>
      <div className={styles.body}>
        <Row className={styles.tableHeader}>
          {headers.map((tableHeader, index) => {
            return (
              <Col key={tableHeader} style={{ textTransform: 'uppercase', marginRight: index * 9 }}>
                {tableHeader}
              </Col>
            );
          })}
        </Row>
        {execsWithNew.map((executive, index) => {
          const isLast = index === execsWithNew.length - 1;
          return (
            <Fragment key={index}>
              <hr style={{ margin: 0 }} />
              <Row key={index} className={styles.rows}>
                <Col>
                  <span data-cy={cypressTags.PROJECT.STUDIO_INPUT}>
                    <Creatable
                      options={companies}
                      onChange={async ({ value }) => {
                        executive.company = value;
                        onChanged && onChanged(executive, index);
                      }}
                      onCreateOption={() => {
                        setDisplayCompanyModal(true);
                        fetchCompanies(companyModalOptions.name, executive, index);
                      }}
                      placeholder="Company"
                      onInputChange={fetchCompanies}
                      value={
                        executive.company
                          ? {
                              value: executive.company._id ? executive.company._id : executive.company,
                              label: executive.company.name ? executive.company.name : executive.company,
                            }
                          : null
                      }
                      styles={selectFieldStyle({
                        indicatorsContainerStyles: { display: 'none' },
                        controlStyles: {
                          border: `1px solid rgba(224, 224, 224, ${isLast ? 1 : 0})`,
                          '&:hover': {
                            border: '1px solid rgb(87, 140, 247)',
                          },
                        },
                      })}
                      isValidNewOption={(inputValue, _values, options) => optionIsValidCreation(inputValue, options)}
                      components={{ Option: CustomeLabelComponent }}
                    />
                  </span>
                </Col>
                {!hideExecType && (
                  <Col>
                    <span>
                      <Select
                        options={execTypes}
                        onChange={({ value }) => {
                          executive.executiveType = value;
                          onChanged && onChanged(executive, index);
                        }}
                        placeholder="Type"
                        value={
                          executive.executiveType
                            ? {
                                value: executive.executiveType,
                                label: executive.executiveType,
                              }
                            : null
                        }
                        styles={selectFieldStyle({
                          indicatorsContainerStyles: { display: 'none' },
                          controlStyles: {
                            border: `1px solid rgba(224, 224, 224, ${isLast ? 1 : 0})`,
                            '&:hover': {
                              border: '1px solid rgb(87, 140, 247)',
                            },
                          },
                        })}
                      />
                    </span>
                  </Col>
                )}
                <Col>
                  <span>
                    <Creatable
                      options={people}
                      onChange={({ value }) => {
                        executive.executive = value;
                        onChanged && onChanged(executive, index);
                      }}
                      onCreateOption={() => {
                        setDisplayExecutiveModal(true);
                        fetchPeople(executiveModalOptions.name, executive, index);
                      }}
                      onInputChange={name => fetchPeople(name, executive)}
                      placeholder="Executive"
                      value={
                        executive.executive
                          ? {
                              value: executive.executive._id ? executive.executive._id : executive.executive,
                              label: executive.executive.name ? executive.executive.name : executive.executive,
                            }
                          : null
                      }
                      styles={selectFieldStyle({
                        indicatorsContainerStyles: { display: 'none' },
                        controlStyles: {
                          border: `1px solid rgba(224, 224, 224, ${isLast ? 1 : 0})`,
                          '&:hover': {
                            border: '1px solid rgb(87, 140, 247)',
                          },
                        },
                      })}
                      components={{ Option: CustomeLabelComponent }}
                    />
                  </span>
                </Col>

                <div
                  className={styles.delete}
                  style={{ visibility: `${!isLast ? 'visible' : 'hidden'}` }}
                  onClick={() => {
                    onDelete && onDelete(index);
                  }}
                >
                  <i className={styles.deleteIcon} />
                </div>
              </Row>
              {errors[index] &&
                errors[index].map((err, errIndex) => {
                  return (
                    <div key={`${index}-${errIndex}`} className={styles.errorText}>
                      {err}
                    </div>
                  );
                })}
            </Fragment>
          );
        })}
      </div>
    </div>
  );
};

const CustomeLabelComponent = props => {
  const { data } = props;
  const label = data.label;
  const firstLetter = data && data?.value?.type?.charAt(0).toUpperCase();

  return (
    <components.Option {...props}>
      <div className="d-flex flex-row align-items-baseline flex-nowrap">
        {firstLetter && (
          <div>
            <span
              onClick={() => {}}
              style={{
                ...iconStyles,
                ...{
                  color: getUserTypeStyles(firstLetter)?.color,
                  backgroundColor: getUserTypeStyles(firstLetter)?.background,
                },
              }}
            >
              {firstLetter}
            </span>
          </div>
        )}
        <div className="ml-1">{label}</div>
      </div>
    </components.Option>
  );
};

const withStyles = styled({
  pane: {
    background: colors.contentBackground,
    marginBottom: 20,
  },
  headerEdit: {
    background: '#90E2D3',
  },
  titleHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px 15px 0px 15px',
  },
  tableHeader: {
    fontSize: 12,
    fontWeight: 100,
    marginTop: 2,
    marginBottom: 2,
    color: 'gray',
  },
  rows: {
    fontWeight: 300,
    fontSize: 12,
    marginTop: 6,
    marginBottom: 6,
  },
  body: {
    padding: '5px 15px',
  },
  title: {
    flex: 1,
    fontSize: 12,
    fontWeight: 300,
    textTransform: 'uppercase',
  },
  icon: {
    marginLeft: 10,
  },
  delete: {
    cursor: 'pointer',
    marginRight: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  emptyDelete: {
    marginRight: '7px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  deleteIcon: icons.smallCrossBlack,
  errorText: {
    color: 'red',
  },
});

export default withStyles(ExecutiveControl);
