import React, { Component } from 'react';
import { connect } from 'react-redux';
import Linkify from 'react-linkify';
import { withStyles } from '@material-ui/core/styles';
import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { notify } from 'react-notify-toast';
import moment from 'moment';
import classnames from 'classnames';

import { getGroups, groupRoles } from '../../../../../api/groups';
import buildSelectItems from '../../../../../support/build-select-items';
import venueTypeValues from '../../../../../support/items/venue-types';
import classificationValues from '../../../../../support/items/classifications';
import genreValues from '../../../../../support/items/genres';
import stateValues from '../../../../../support/items/states';
import capacityValues from '../../../../../support/items/capacities';
import ItemListCard from '../../../../../components/item-list-card/item-list-card.js';
import { CalendarProject, Card, Info, Input, Select, Multiselect } from '@united-talent-agency/julius-frontend-components';
import { updateProject } from '@united-talent-agency/julius-frontend-store';

import { getPeopleIndex } from '../../../../../support/algolia/get-index';
import { searchClient } from '../../../../../support/algolia/algolia-search-client';

class TouringInformation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      ...props.project,
      genre: props.project.genre,
    };
  }

  render() {
    const { onCancel, project, classes } = this.props;

    const onClick = (field, value) => {
      project[field] = value;
      this.setState({ [field]: value });
    };

    const infoItems = [
      {
        inputName: 'venue_type',
        title: 'Event Type',
        type: 'select',
        hidden: false,
        collection: venueTypeValues,
        onClick: onClick,
      },
      { inputName: 'opened_date', title: 'Starts', type: 'calendar', hidden: false },
      { inputName: 'thru_date', title: 'Thru', type: 'calendar', hidden: false },
      {
        inputName: 'budget',
        title: 'Topline Budget',
        type: 'text',
        hidden: false,
      },
      {
        inputName: 'genre',
        title: 'Genre',
        type: 'multiselect',
        hidden: false,
        collection: genreValues,
      },
      { inputName: 'capacity', title: 'Capacity', type: 'select', collection: capacityValues, onClick, hidden: false },
      { inputName: 'locationCity', title: 'City', type: 'text', hidden: false },
      {
        inputName: 'locationState',
        title: 'State',
        type: 'select',
        collection: stateValues,
        hidden: false,
        onClick: onClick,
      },
      { inputName: 'blurb', title: 'Blurb', type: 'text', hidden: false },
      {
        inputName: 'classification',
        title: 'Classification',
        type: 'select',
        hidden: false,
        collection: classificationValues,
        onClick: onClick,
      },
    ];

    return (
      <ThemeProvider theme={theme}>
        <div className={classes.columns}>
          <div className={classes.infoCards}>
            <div className={classes.column}>{this.renderInfoCard(infoItems, 'Project Info', onCancel)}</div>

            <div className={classes.column}>
              {this.renderItemListCard(
                'Parent Companies',
                'parentCompanies',
                name => {
                  const role = groupRoles['Parent Companies'];
                  return getGroups(name, { role }).then(({ data = [] }) => data);
                },
                this.props
              )}
              {this.renderItemListCard(
                'Promoters',
                'promoters',
                name => {
                  const role = groupRoles.Promoters;
                  return getGroups(name, { role }).then(({ data = [] }) => data);
                },
                this.props
              )}
              {this.renderItemListCard(
                'Contacts',
                'contacts',
                name => {
                  if(name !== ''){
                    const index = getPeopleIndex();
                    const indexSetting = {
                      filters: "(type: 'Industry Contact')",
                      hitsPerPage: 10,
                      page: 0,
                      restrictSearchableAttributes: ['name'],
                    };
                    return searchClient(index, name, indexSetting).then(({ hits: results }) => {
                      return results;
                    });
                  }
                  return Promise.resolve([]);
                },
                this.props
              )}
              {this.renderItemListCard(
                'Territory Agents',
                'pointAgents',
                name => {
                  if(name !== ''){
                    const index = getPeopleIndex();
                    const indexSetting = {
                      filters: "(type: 'Employee') OR (projectRoles.k: 'agent')",
                      hitsPerPage: 10,
                      page: 0,
                      restrictSearchableAttributes: ['name'],
                    };
                    return searchClient(index, name, indexSetting).then(({ hits: results }) => {
                      return results;
                    });
                  }
                  return Promise.resolve([]);
                },
                this.props
              )}
            </div>
          </div>
        </div>
      </ThemeProvider>
    );
  }

  // eslint-disable-next-line no-undef
  renderItemListCard = (title, projectElement, itemFinder, props) => {
    const { project, dispatch, originals, onChange, changedItems } = props;
    //const { originals } = this.state;
    const items = project[projectElement];

    const itemSanitizer = function(items) {
      return items.filter(c => !!c._id);
    };

    return (
      <ItemListCard
        title={title}
        items={items}
        onSave={() => {
          dispatch(updateProject(project._id, { [projectElement]: itemSanitizer(items) })).then(results => {
            notify.show(`${title} Saved`, 'custom', 2000, styles.toast);
            const updated = results.body[projectElement];
            project[projectElement] = updated;
            originals[projectElement] = JSON.parse(JSON.stringify(updated));
            onChange(projectElement);
          });
        }}
        onCancel={() => {
          project[projectElement] = JSON.parse(JSON.stringify(originals[projectElement]));
          onChange(projectElement);
        }}
        onChange={(itemName, index) => {
          items[index] = { name: itemName };
          onChange(projectElement);
        }}
        onSelect={(item, index) => {
          items[index] = item;
          onChange(projectElement);
        }}
        onDelete={index => {
          items.splice(index, 1);
          onChange(projectElement);
        }}
        isDirty={changedItems.some(i => {
          return i === projectElement;
        })}
        finder={itemFinder}
      />
    );
  };

  // eslint-disable-next-line no-undef
  renderInfoCard = (infoItems, title, onCancel, blockView) => {
    const { project, saveChanges, styles } = this.props;

    return (
      <Card title={title} canEdit saveChanges={saveChanges} onCancel={onCancel}>
        {({ updateBy, isEditing }) => {
          const updateProject = data => {
            updateBy('project', project._id, data);
          };

          const otherInformation = blockView
            ? this.blockViewCard(project, styles, infoItems)
            : this.viewCard(project, styles, infoItems);
          const editInformation = this.editCard(infoItems, project, styles, updateProject);
          return (
            <div>
              <Info type="text" name="Last Updated">
                {project.last_updated_date && moment(project.last_updated_date).format('YYYY-MM-DD')}
              </Info>
              {isEditing ? editInformation : otherInformation}
            </div>
          );
        }}
      </Card>
    );
  };

  // eslint-disable-next-line no-undef
  viewCard = (project, classes, infoItems) => {
    return (
      <div>
        {infoItems
          .filter(item => !item.hidden)
          .map((infoItem, index) => (
            <Info type="text" name={infoItem.title} className={classes && classes.input} key={index}>
              {this.parseValue(infoItem, project)}
            </Info>
          ))}
      </div>
    );
  };

  // eslint-disable-next-line no-undef
  blockViewCard = (project, classes, infoItems) => {
    return (
      <div>
        {infoItems
          .filter(item => !item.hidden)
          .map(infoItem => {
            return this.parseValue(infoItem, project, classes);
          })}
      </div>
    );
  };

  parseValue(item, project, classes) {
    switch (item.type) {
      case 'calendar':
        return !!project[item.inputName] && typeof project[item.inputName] === 'string'
          ? moment(project[item.inputName]).format('YYYY-MM-DD')
          : '';
      case 'multiselect':
        return (
          project[item.inputName] &&
          project[item.inputName]
            .join(',')
            .split(',')
            .filter(item => item !== null && item.trim() !== '')
            .sort()
            .map((item, index) => (
              <div key={index} className={classes && classes.blockView}>
                {item}
              </div>
            ))
        );
      default:
        switch (item.inputName) {
          case 'blurb':
            return <Linkify>{project[item.inputName]}</Linkify>;
          default:
            return project[item.inputName];
        }
    }
  }

  // eslint-disable-next-line no-undef
  editCard = (infoItems, project, classes, updateProject) => {
    return (
      <div>
        {infoItems
          .filter(item => !item.hidden && !item.disabled)
          .map((infoItem, index) => this.inputField(infoItem, index, project, styles, updateProject))}
      </div>
    );
  };

  // eslint-disable-next-line no-undef
  inputField = (infoItem, index, project, classes, updateProject) => {
    switch (infoItem.type) {
      case 'calendar':
        // eslint-disable-next-line no-case-declarations
        const date =
          !!project[infoItem.inputName] && typeof project[infoItem.inputName] === 'string'
            ? moment(project[infoItem.inputName]).format('YYYY-MM-DD')
            : '';
        return (
          <CalendarProject
            key={`${index}_${infoItem.inputName}`}
            title={infoItem.title}
            value={project && date}
            className={classnames(classes.input, classes.section)}
            onChange={date => updateProject({ [infoItem.inputName]: date && date.valueOf() })}
          />
        );
      case 'select':
        return (
          <Select
            key={`${index}_${infoItem.inputName}`}
            title={infoItem.title}
            items={buildSelectItems(
              infoItem.inputName,
              infoItem.collection,
              project[infoItem.inputName],
              (field, value) => {
                infoItem.onClick(field, value);
                updateProject({ [field]: value });
              }
            )}
            className={classes.selectInput}
          >
            {project[infoItem.inputName]}
          </Select>
        );
      case 'multiselect':
        return (
          <Multiselect
            key={`${index}_${infoItem.inputName}`}
            value={this.state[infoItem.inputName].join(',')}
            title={infoItem.title}
            options={infoItem.collection.map(item => {
              return { value: item, label: item };
            })}
            isSearchable={true}
            _onChange={value => {
              infoItem.onChange && infoItem.onChange(infoItem.inputName, value.split(','));
              this.setState({ [infoItem.inputName]: value.split(',') });
              updateProject({ [infoItem.inputName]: value.split(',') });
            }}
          />
        );
      default:
        return (
          <Input
            key={`${index}_${infoItem.inputName}`}
            type={infoItem.type}
            title={infoItem.title}
            value={project ? project[infoItem.inputName] : ''}
            className
            onChange={value => updateProject({ [infoItem.inputName]: value })}
          />
        );
    }
  };
}

// UTA grey theme
const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiSelect: {
      select: {
        color: '​#808080',
      },
    },
    MuiFormLabel: {
      root: {
        color: '#808080',
        letterSpacing: -0.25,
      },
    },
    MuiOutlinedInput: {
      root: {
        color: '#808080',
      },
      inputSelect: {
        color: '#808080',
      },
    },
  },
});

const styles = {
  columns: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
  },
  column: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    '& + &': {
      marginLeft: 20,
    },
  },
  blockView: {
    color: 'rgb(33,37,41)',
    fontFamily: 'Gotham, sans-serif',
    fontSize: '12px',
    borderBottomColor: 'rgb(204,204,204)',
    borderBottomStyle: 'dotted',
    borderBottomWidth: '1px',
  },
  actsCard: {
    marginTop: 5,
    marginBottom: 25,
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    '& div:first-child': {
      width: '100%',
    },
  },
  infoCards: {
    marginTop: 5,
    marginBottom: 25,
    padding: 15,
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
  },
  toast: { background: '#000000', text: '#FFFFFF' },
};

const mapStateToProps = state => {
  const { project } = state.projects;
  const { user } = state;

  return {
    ...state,
    promoters: project.promoters,
    user: user.user,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(TouringInformation));
