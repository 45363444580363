import { grey } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { debounce } from 'lodash';
import moment from 'moment';
import { Select } from '@united-talent-agency/julius-frontend-components';
import React, { Component } from 'react';
import { styled } from 'react-free-style';

import DateRangePicker from '../date-range-picker/date-range-picker';
import FilterCheckbox from '../filter-checkbox/filter-checkbox';
import ProfileFilter from '../profile-filter/profile-filter';
import buildSelectItems from '../../support/build-select-items';
import genders from '../../support/items/genders';
import ethnicities from '../../support/items/ethnicities';
import DropdownFilter from '../dropdown-filter/dropdown-filter';
import InfiniteScrollDropdown from '../infiniate-scroll-select/infinite-scroll-select';
import CollapseIcon from '../collapse-icon/collapse-icon';
import { castingTypesByProjectType } from '../../support/items/casting-types';
import { defaultStatuses, statusesByProjectType } from '../../support/items/statuses';
import { Grid, TextField, Typography, Box, MenuItem } from '@material-ui/core';
import distributions from '../../support/items/distributions';
import { useState, useEffect } from 'react';

const Separator = () => <hr style={{ marginTop: '20px' }}></hr>;

export const SelectedCastingStatusCheckboxes = ({ filterModel, onEvent }) => {
  const { includeArchivedCastings, clientRolesOnly } = filterModel;
  let selectedCastingStatusOptions = [];
  includeArchivedCastings &&
    selectedCastingStatusOptions.push({
      name: 'Archived Castings',
      desiredValue: false,
      property: 'includeArchivedCastings',
    });
  clientRolesOnly &&
    selectedCastingStatusOptions.push({
      name: 'Client Roles Only',
      property: 'clientRolesOnly',
      desiredValue: false,
    });
  return selectedCastingStatusOptions.map((checkbox, i) => (
    <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
      <span onClick={() => onEvent(checkbox.property, false)} style={{ cursor: 'pointer' }}>
        <i className="fa fa-check-square fa-fw" />
      </span>
      {checkbox.name}
    </div>
  ));
};

export const AgeRangeSelection = ({ styles, filterModel, filterCastings, recordEvent }) => {
  const [ageRange, setAgeRange] = useState({ ageMin: filterModel.ageMin || 0, ageMax: filterModel.ageMax || 0 });

  useEffect(() => {
    setAgeRange({
      ageMin: filterModel.ageMin || 0,
      ageMax: filterModel.ageMax || 0,
    });
  }, [filterModel.ageMin, filterModel.ageMax]);

  const onEventAgeRange = async (ageMinMaxType, value, filterModel) => {
    value = value.replace(/\D/g, '');
    setAgeRange({ ...ageRange, [ageMinMaxType]: value });

    // have to debounce so that we don't send data down in an intermediate state
    const apiCall = debounce(async filterModel => await filterCastings(filterModel), 1500); //remove non-numeric characters

    filterModel[ageMinMaxType] = value;

    if (ageMinMaxType === 'ageMin' && value < filterModel.ageMax) {
      filterModel.ageRange = `${value || 'Any'}-${filterModel.ageMax || 'Any'}`;
    } else {
      filterModel.ageRange = `${filterModel.ageMin || 'Any'}-${value || 'Any'}`;
    }

    if (!filterModel.ageMax && !filterModel.ageMin) {
      delete filterModel.ageRange;
      delete filterModel.ageMin;
      delete filterModel.ageMax;
    }

    recordEvent({ data: `Age_range_${value}` });
    await apiCall(filterModel);
  };

  return (
    <React.Fragment>
      <div className="ml-1 mt-4 row">
        <h6 className="mx-0 mb-2 mt-0 p-0">Age Range</h6>
      </div>
      <Grid id="age-range-search" container spacing={4}>
        <Grid item xs={6}>
          <Typography component="div" className={styles.gridSection}>
            <Box fontSize="12px">FROM</Box>
          </Typography>
          <TextField
            id="ageSearchMin"
            fullWidth
            margin="dense"
            variant="outlined"
            name="Age From"
            placeholder="Age From"
            value={ageRange.ageMin || ''}
            type="string"
            onChange={event => onEventAgeRange('ageMin', event.target.value, filterModel)}
          />
        </Grid>
        <Grid item xs={6}>
          <Typography component="div">
            <Box fontSize="12px">TO</Box>
          </Typography>
          <TextField
            id="ageSearchMax"
            fullWidth
            margin="dense"
            variant="outlined"
            name="Age To"
            placeholder="Age To"
            value={ageRange.ageMax || ''}
            type="string"
            onChange={event => onEventAgeRange('ageMax', event.target.value, filterModel)}
          />
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export const GenderSelection = ({ filterModel, onEventCollectionWithExclude }) => {
  return (
    <TextField
      select
      variant="outlined"
      fullWidth
      label="Gender"
      value={filterModel.gender?.include?.[0] || filterModel.gender?.exclude?.[0] || ''}
      onChange={event => onEventCollectionWithExclude('gender', event.target.value, filterModel)}
      margin="dense"
    >
      {genders.map(option => (
        <MenuItem key={option} value={option}>
          {option}
        </MenuItem>
      ))}
    </TextField>
  );
};

const EthnicitySelection = ({ filterModel, onRemoveCheckbox, filterCastings, recordEvent }) => {
  const ethnicitiesTags = [...(filterModel?.ethnicities?.include || []), ...(filterModel?.ethnicities?.exclude || [])];

  const onEventEthnicitiesWithExclude = async item => {
    if (!filterModel.ethnicities || !filterModel.ethnicities.include || !filterModel.ethnicities.exclude) {
      filterModel.ethnicities = { include: [], exclude: [] };
    }
    filterModel.ethnicities.include = filterModel.ethnicities?.include?.filter(type => type !== item).concat(item);
    filterModel.ethnicities.exclude = filterModel.ethnicities?.exclude?.filter(type => type !== item);

    recordEvent({ data: `ethnicities.include_${item}` });
    await filterCastings(filterModel);
  };

  return (
    <React.Fragment>
      <TextField
        select
        variant="outlined"
        fullWidth
        label="Ethnicity"
        placeholder="Ethnicity"
        value=""
        type="string"
        onChange={event => onEventEthnicitiesWithExclude(event.target.value)}
        margin="dense"
      >
        {ethnicities.map(option => (
          <MenuItem key={option} value={option}>
            {option}
          </MenuItem>
        ))}
      </TextField>
      {ethnicitiesTags.map((tag, index) => (
        <div key={index} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
          <span onClick={() => onRemoveCheckbox('ethnicities', tag)} style={{ cursor: 'pointer' }}>
            <i className="fa fa-check-square fa-fw" />
          </span>
          {tag}
        </div>
      ))}
    </React.Fragment>
  );
};

const ProfileSelection = ({ onEventCollectionWithExclude, filterModel, onRemoveCheckbox }) => {
  const profileTags = [
    ...(filterModel.profiles && filterModel.profiles.include ? filterModel.profiles.include : []),
    ...(filterModel.profiles && filterModel.profiles.exclude ? filterModel.profiles.exclude : []),
  ];
  return (
    <React.Fragment>
      <ProfileFilter
        label="Profiles (genre, status, etc)"
        onSelectProfile={profile => onEventCollectionWithExclude('profiles', profile, filterModel)}
        filterModel={filterModel}
      />
      {profileTags.map((tag, i) => (
        <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
          <span onClick={() => onRemoveCheckbox('profiles', tag)} style={{ cursor: 'pointer' }}>
            <i className="fa fa-check-square fa-fw" />
          </span>
          {tag.name}
        </div>
      ))}
    </React.Fragment>
  );
};

const CastingCheckboxes = ({ onEvent, filterModel }) => {
  const castingCheckboxes = [
    { id: 'includeArchivedProjects', title: 'Include Archived Projects', event: onEvent },
    { id: 'excludeArchivedRoles', title: 'Exclude Archived Roles', event: onEvent },
    { id: 'newInformationOnly', title: 'New Information Only', event: onEvent },
  ];
  return castingCheckboxes.map((checkbox, index) => {
    const { id, title, event } = checkbox;
    return <FilterCheckbox key={index} id={id} checked={filterModel[id] || ''} title={title} onChange={event} />;
  });
};

export const TypeCheckboxes = ({ onEvent, filterModel, recordEvent, filterCastings }) => {
  const onEventTypesWithExclude = async (item, checked, filterModel) => {
    if (!filterModel.types) filterModel.types = {};
    if (!filterModel.types.include) filterModel.types.include = [];
    if (!filterModel.types.exclude) filterModel.types.exclude = [];

    if (checked) {
      filterModel.types.include = filterModel.types?.include?.filter(type => type !== item).concat(item);
      filterModel.types.exclude = filterModel.types?.exclude?.filter(type => type !== item);
    } else {
      filterModel.types.include = filterModel.types?.include?.filter(type => type !== item);
      filterModel.types.exclude = filterModel.types?.exclude?.filter(type => type !== item);
    }

    recordEvent({ data: `types.${checked ? 'include' : 'removed'}_${item}` });
    await filterCastings(filterModel);
  };

  const type = (filterModel.projectTypes || []).length === 1 ? filterModel.projectTypes[0] : 'Any';
  const roles =
    castingTypesByProjectType[type] &&
    castingTypesByProjectType[type].map(castingType => {
      return { id: castingType, title: castingType, event: onEvent };
    });
  const isChecked = title => {
    return filterModel.types?.include?.includes(title) || filterModel.types?.exclude?.includes(title) || false;
  };
  return (
    roles && (
      <React.Fragment>
        <div className="ml-1 mt-4 row">
          <h6 className="mx-0 mb-2 mt-0 p-0">Type</h6>
        </div>
        {roles.map((checkbox, index) => {
          const { id, title } = checkbox;
          return (
            <FilterCheckbox
              key={index}
              id={id}
              checked={isChecked(title)}
              title={title}
              onChange={(index, checked) => {
                onEventTypesWithExclude(title, checked, filterModel);
              }}
            />
          );
        })}
      </React.Fragment>
    )
  );
};

export const StatusSelection = ({ filterModel, recordEvent, filterCastings }) => {
  const onEventStatusesWithExclude = async (item, checked, filterModel) => {
    if (!filterModel.statuses) filterModel.statuses = {};
    if (!filterModel.statuses.include) filterModel.statuses.include = [];
    if (!filterModel.statuses.exclude) filterModel.statuses.exclude = [];

    if (checked) {
      filterModel.statuses.include = filterModel.statuses.include.filter(status => status !== item).concat(item);
      filterModel.statuses.exclude = filterModel.statuses.exclude.filter(status => status !== item);
    } else {
      filterModel.statuses.include = filterModel.statuses.include.filter(status => status !== item);
      filterModel.statuses.exclude = filterModel.statuses.exclude.filter(status => status !== item);
    }

    recordEvent({ data: `statuses.${checked ? 'include' : 'removed'}_${item}` });
    await filterCastings(filterModel);
  };

  const type = (filterModel.projectTypes || []).length === 1 ? filterModel.projectTypes[0] : 'Any';
  const statuses = type === 'Any' ? defaultStatuses : statusesByProjectType[type] || [];

  const isChecked = status => {
    return filterModel.statuses?.include?.includes(status) || filterModel.statuses?.exclude?.includes(status) || false;
  };

  return (
    <React.Fragment>
      <div className="ml-1 mt-4 row">
        <h6 className="mx-0 mb-2 mt-0 p-0">Status</h6>
      </div>
      {statuses.map((status, i) => (
        <FilterCheckbox
          key={i}
          id={i}
          checked={isChecked(status)}
          title={status}
          onChange={(index, checked) => {
            onEventStatusesWithExclude(status, checked, filterModel);
          }}
        />
      ))}
    </React.Fragment>
  );
};

const DateSearch = ({ filterModel, onEvent, styles, filterCastings }) => {
  const onEventDateChange = async (startDate, endDate) => {
    const isDiffStartDate = filterModel.castingDateStart !== startDate;
    const isDiffEndDate = filterModel.castingDateEnd !== endDate;

    if (isDiffStartDate || isDiffEndDate) {
      filterModel.castingDateStart = startDate;
      filterModel.castingDateEnd = endDate;
      isDiffStartDate && onEvent('castingDateStart', startDate);
      isDiffEndDate && onEvent('castingDateEnd', endDate);
      await filterCastings(filterModel);
    }
  };
  const dateSearchItems = buildSelectItems(
    'castingDateType',
    ['Start Date', 'Last Updated', 'Created'],
    filterModel.castingDateType,
    onEvent
  );
  const castingDateStart = filterModel.castingDateStart && moment(filterModel.castingDateStart);
  const castingDateEnd = filterModel.castingDateEnd && moment(filterModel.castingDateEnd);
  return (
    <React.Fragment>
      <Select title="Date Search" items={dateSearchItems} className={styles.selectInput}>
        {filterModel.castingDateType || 'Start Date'}
      </Select>

      <div className="mt-2 p-0 col w-100">
        <DateRangePicker
          startDate={castingDateStart}
          startDateId="castingDateStart"
          endDate={castingDateEnd}
          endDateId="castingDateEnd"
          onDatesChange={onEventDateChange}
        />
      </div>
    </React.Fragment>
  );
};

export const ProductionCompanySelection = ({ filterModel, onEventCollectionWithExclude, onRemoveCheckbox }) => {
  const productionCompanyTags = [
    ...(filterModel.productionCompanies && filterModel.productionCompanies.include
      ? filterModel.productionCompanies.include
      : []),
    ...(filterModel.productionCompanies && filterModel.productionCompanies.exclude
      ? filterModel.productionCompanies.exclude
      : []),
  ];
  return (
    <React.Fragment>
      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
        <InfiniteScrollDropdown
          label="Production Co."
          onSelectCompany={company => onEventCollectionWithExclude('productionCompanies', company, filterModel)}
          companies={
            filterModel.productionCompanies && filterModel.productionCompanies.include
              ? filterModel.productionCompanies.include
              : []
          }
        />
      </div>
      {productionCompanyTags.map((tag, i) => (
        <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
          <span onClick={() => onRemoveCheckbox('productionCompanies', tag)} style={{ cursor: 'pointer' }}>
            <i className="fa fa-check-square fa-fw" />
          </span>
          {tag.name}
        </div>
      ))}
    </React.Fragment>
  );
};

export const StudioSelection = ({ filterModel, onEventCollectionWithExclude, onRemoveCheckbox }) => {
  const studioTags = [
    ...(filterModel.studios && filterModel.studios.include ? filterModel.studios.include : []),
    ...(filterModel.studios && filterModel.studios.exclude ? filterModel.studios.exclude : []),
  ];
  return (
    <React.Fragment>
      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
        <InfiniteScrollDropdown
          label="Studio"
          onSelectCompany={company => onEventCollectionWithExclude('studios', company, filterModel)}
          companies={filterModel.studios && filterModel.studios.include ? filterModel.studios.include : []}
        />
      </div>
      {studioTags.map((tag, i) => (
        <div key={i} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
          <span onClick={() => onRemoveCheckbox('studios', tag)} style={{ cursor: 'pointer' }}>
            <i className="fa fa-check-square fa-fw" />
          </span>
          {tag.name}
        </div>
      ))}
    </React.Fragment>
  );
};

export const NetworkSelection = ({ filterModel, onRemoveCheckbox, onEventCollectionWithExclude }) => {
  const networkTags = [
    ...(filterModel.networks && filterModel.networks.include ? filterModel.networks.include : []),
    ...(filterModel.networks && filterModel.networks.exclude ? filterModel.networks.exclude : []),
  ];
  return (
    <React.Fragment>
      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
        <InfiniteScrollDropdown
          label="Network"
          onSelectCompany={company => onEventCollectionWithExclude('networks', company, filterModel)}
          companies={filterModel.networks && filterModel.networks.include ? filterModel.networks.include : []}
        />
      </div>
      {networkTags.map((tag, index) => (
        <div key={index} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
          <span onClick={() => onRemoveCheckbox('networks', tag)} style={{ cursor: 'pointer' }}>
            <i className="fa fa-check-square fa-fw" />
          </span>
          {tag.name}
        </div>
      ))}
    </React.Fragment>
  );
};

export const DistributionSelection = ({ filterModel, distributions, onRemoveCheckbox, onEventArrayWithExclude }) => {
  const distributionTags = [
    ...(filterModel.distribution && filterModel.distribution.include ? filterModel.distribution.include : []),
    ...(filterModel.distribution && filterModel.distribution.exclude ? filterModel.distribution.exclude : []),
  ];
  return (
    <React.Fragment>
      <div style={{ marginTop: '5px', marginBottom: '5px' }}>
        <DropdownFilter
          title="Distribution"
          results={distributions.map(d => {
            return { _id: d, title: d };
          })}
          onSelectStatus={dist => {
            onEventArrayWithExclude('distribution', dist, filterModel);
          }}
        />
      </div>
      {distributionTags.map((tag, index) => (
        <div key={index} style={{ paddingLeft: 10, fontSize: 13, fontWeight: 300 }}>
          <span onClick={() => onRemoveCheckbox('distribution', tag)} style={{ cursor: 'pointer' }}>
            <i className="fa fa-check-square fa-fw" />
          </span>
          {tag}
        </div>
      ))}
    </React.Fragment>
  );
};

export const CastingStatusSelection = ({ filterModel, onEvent, onEventArrayWithExclude }) => {
  let castingStatusOptions = [];
  filterModel.includeArchivedCastings
    ? castingStatusOptions.push({ _id: 'active', title: 'Active Castings' })
    : castingStatusOptions.push({ _id: 'includeArchivedCastings', title: 'Include Archived Castings' });
  if (!filterModel.clientRolesOnly) castingStatusOptions.push({ _id: 'clientRolesOnly', title: 'Client Roles Only' });
  return (
    <div style={{ marginTop: '20px' }}>
      <DropdownFilter
        title="Casting Status"
        results={castingStatusOptions}
        onSelectStatus={status => {
          if (status === 'clientRolesOnly') {
            onEventArrayWithExclude('clientRolesOnly', 'Clients Roles Only', filterModel);
          } else if (status === 'active') onEvent('includeArchivedCastings', false);
          else onEvent(status, true);
        }}
      />
      <SelectedCastingStatusCheckboxes filterModel={filterModel} onEvent={onEvent} />
    </div>
  );
};

class CastingFilterForm extends Component {
  // eslint-disable-next-line no-undef
  onRemoveCheckbox = async (collection, item) => {
    const { filterModel, recordEvent } = this.props;

    if (filterModel[collection]) {
      if (filterModel[collection].include)
        filterModel[collection].include = filterModel[collection].include.filter(p => p !== item);

      if (filterModel[collection].exclude)
        filterModel[collection].exclude = filterModel[collection].exclude.filter(p => p !== item);
    }

    recordEvent({ data: `${collection}_${item}_${null}` });
    await this.props.filterCastings(filterModel);
  };

  // eslint-disable-next-line no-undef
  onEvent = async (field, value) => {
    const { filterModel, recordEvent } = this.props;
    filterModel[field] = value;

    recordEvent({ data: `${field}_${value}` });
    await this.props.filterCastings(filterModel);
  };

  // eslint-disable-next-line no-undef
  onEventStatuses = async (item, checked) => {
    const { filterModel, recordEvent } = this.props;
    const statuses = filterModel.statuses || [];

    if (checked && statuses.indexOf(item) === -1) {
      statuses.push(item);
      filterModel.statuses = statuses;
    }
    if (!checked) {
      filterModel.statuses = statuses.filter(s => {
        return s !== item;
      });
    }

    recordEvent({ data: `statuses_${item}` });
    await this.props.filterCastings(filterModel);
  };

  // eslint-disable-next-line no-undef
  onEventCollectionWithExclude = async (collection, item, filterModel) => {
    const { recordEvent } = this.props;
    if (filterModel[collection]) {
      filterModel[collection].include = filterModel[collection].include || [];
      filterModel[collection].include = [
        ...filterModel[collection].include.filter(obj => (obj._id ? obj._id !== item._id : obj.name !== item.name)),
        item,
      ];
      filterModel[collection].exclude = filterModel[collection].exclude || [];
      filterModel[collection].exclude.filter(obj => (obj._id ? obj._id !== item._id : obj.name !== item.name));

      recordEvent({ data: `${collection}.'include'_${item && item.name}` });
    } else {
      filterModel[collection] = { include: [item] };
    }
    await this.props.filterCastings(filterModel);
  };

  // eslint-disable-next-line no-undef
  onEventArrayWithExclude = async (collection, item, filterModel) => {
    const { recordEvent } = this.props;

    if (!filterModel[collection]) {
      filterModel[collection] = { include: [] };
    }

    filterModel[collection].include = filterModel[collection].include
      .filter(obj => {
        return obj !== item;
      })
      .concat(item);

    if (filterModel[collection].exclude) {
      filterModel[collection].exclude = filterModel[collection].exclude.filter(obj => {
        return obj !== item;
      });
    }
    recordEvent({ data: `${collection}.${'include'}_${item}` });

    await this.props.filterCastings(filterModel);
  };

  render() {
    const { filterModel, styles, searchCompany, collapseEvent, filterCastings, recordEvent } = this.props;
    const type = (filterModel.projectTypes || []).length === 1 ? filterModel.projectTypes[0] : 'Any';
    const isAnyType = type === 'Any';
    const isFeature = type === 'Feature';
    const isTv = type === 'Television' || type === 'Unscripted';
    const isTheatre = type === 'Theatre';

    return (
      <div className="m-0 py-0 pr-2 pl-0 col">
        <div className="ml-1 mt-4 row">
          <h5 className="mx-0 mb-2 mt-0 p-0">Casting Filters</h5>
          <div style={{ marginTop: 0, marginRight: 25, cursor: 'pointer' }} className="ml-auto" onClick={collapseEvent}>
            <CollapseIcon iconDirection="<" />
          </div>
        </div>
        <TypeCheckboxes
          filterModel={filterModel}
          filterCastings={filterCastings}
          recordEvent={recordEvent}
          onEvent={this.onEvent}
        />
        <Separator />
        <StatusSelection recordEvent={recordEvent} filterModel={filterModel} filterCastings={filterCastings} />
        <Separator />
        <ThemeProvider theme={theme}>
          <GenderSelection filterModel={filterModel} onEventCollectionWithExclude={this.onEventCollectionWithExclude} />
          <EthnicitySelection
            filterModel={filterModel}
            onRemoveCheckbox={this.onRemoveCheckbox}
            filterCastings={filterCastings}
            recordEvent={recordEvent}
          />
          <Separator />
          <AgeRangeSelection
            filterModel={filterModel}
            styles={styles}
            filterCastings={filterCastings}
            recordEvent={recordEvent}
          />
        </ThemeProvider>
        <div className={styles.section}>
          <DateSearch
            filterModel={filterModel}
            styles={styles}
            filterCastings={filterCastings}
            onEvent={this.onEvent}
          />
        </div>
        <CastingStatusSelection
          onEvent={this.onEvent}
          onEventArrayWithExclude={this.onEventArrayWithExclude}
          filterModel={filterModel}
        />
        <Separator />
        <div className="ml-1 mt-4 row">
          <h5 className="mx-0 mb-2 mt-0 p-0">Project Filters</h5>
        </div>
        <ProfileSelection
          filterModel={filterModel}
          onEventCollectionWithExclude={this.onEventCollectionWithExclude}
          onRemoveCheckbox={this.onRemoveCheckbox}
        />
        <Separator />
        <div style={{ margin: '5px 0' }}>
          <CastingCheckboxes filterModel={filterModel} onEvent={this.onEvent} />
          <Separator />
        </div>
        {(isTv || isAnyType) && (
          <NetworkSelection
            filterModel={filterModel}
            onRemoveCheckbox={this.onRemoveCheckbox}
            onEventCollectionWithExclude={this.onEventCollectionWithExclude}
            searchCompany={searchCompany}
          />
        )}

        {(isFeature || isTv || isAnyType) && (
          <StudioSelection
            filterModel={filterModel}
            onEventCollectionWithExclude={this.onEventCollectionWithExclude}
            onRemoveCheckbox={this.onRemoveCheckbox}
            searchCompany={searchCompany}
          />
        )}

        {(isFeature || isTv || isTheatre || isAnyType) && (
          <ProductionCompanySelection
            filterModel={filterModel}
            onEventCollectionWithExclude={this.onEventCollectionWithExclude}
            searchCompany={searchCompany}
            onRemoveCheckbox={this.onRemoveCheckbox}
          />
        )}
        {(isTv || isAnyType) && (
          <React.Fragment>
            <Separator />
            <DistributionSelection
              filterModel={filterModel}
              onRemoveCheckbox={this.onRemoveCheckbox}
              onEventArrayWithExclude={this.onEventArrayWithExclude}
              distributions={distributions}
            />
          </React.Fragment>
        )}
      </div>
    );
  }
}

const theme = createTheme({
  palette: {
    primary: grey,
  },
  overrides: {
    MuiOutlinedInput: {
      root: {
        backgroundColor: 'white',
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
  },
});

const withStyles = styled({
  selectInput: {
    marginTop: 3,
  },
  section: {
    marginTop: 15,
  },
  gridSection: {
    marginLeft: 5,
  },
});

export default withStyles(CastingFilterForm);
