import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import { AgGridReact as AgGrid } from 'ag-grid-react';
import ActionButtons from '../../../../../../components/action-buttons/action-buttons';
import { ProjectBadges as CastingBadges } from './../../../../../../components/filter-badges/filter-project-badges';
import SortableColumnHeader from '../../../../../../components/sortable-column-header/sortable-column-header';

const Grid = ({
  castings,
  buttons,
  onSelectedChanged,
  onSelectAllChanged,
  filterModel,
  selectAll,
  selection,
  onFetchMore,
  resultCount,
  maxCount,
  onSortChanged,
  resetFilters,
  filterBadges,
  sortColumn,
  sortDirection,
}) => {
  const gridRef = useRef(null);

  const refreshGrid = useCallback(() => {
    gridRef.current.api.refreshHeader();
  }, []);

  const showLoading = useCallback(() => {
    gridRef.current.api.showLoadingOverlay();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const [isSelected, setIsSelected] = useState(null);

  const getRowId = useMemo(() => {
    return params => params.data.id;
  }, []);

  const columDefinations = (currentSelectedCol, direction) => {
    return [
      {
        headerName: 'Project',
        field: 'project',
        width: 250,
        wrapText: true,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <div>
              <div>{params.data.project.name}</div>
              <React.Fragment>
                {params?.data?.project?.studios?.map((item, index, arr) => (
                  <span key={index}>{item + (index !== arr.length - 1 ? '/' : '')}</span>
                ))}
              </React.Fragment>
            </div>
          );
        },
        checkboxClass: 'checked:border-primary checked:bg-primary',
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Role',
        field: 'name',
        width: 150,
        wrapText: true,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <div className="d-flex flex-column">
              <div>{params.data.name}</div>
              <div>{params.data.ageMin && params.data.ageMax && `${params.data.ageMin}-${params.data.ageMax}`}</div>
            </div>
          );
        },
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Role Type',
        field: 'roleType',
        width: 150,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Start',
        field: 'Start',
        width: 180,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Updated',
        field: 'Updated',
        width: 170,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Created',
        field: 'Created',
        width: 180,
        hide: true,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Talent',
        field: 'talent',
        width: 180,
        wrapText: true,
        autoHeight: true,
        cellRenderer: params => {
          return (
            <div
              className={
                params.data?.talent?.type === 'Client' || params.data?.talent?.isRepresentedByUTA
                  ? 'font-weight-bold'
                  : ''
              }
            >
              {params.data?.talent?.name}
            </div>
          );
        },
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Executives',
        field: 'executives',
        cellRenderer: params => {
          return (
            <div className="d-flex flex-column">
              {params?.data?.executives?.directors?.length > 0 && (
                <div>
                  <span>D:</span>
                  {params?.data?.executives?.directors?.map((item, index, arr) => (
                    <span key={index} className="pl-1">
                      {item + (index !== arr.length - 1 ? ',' : '')}
                    </span>
                  ))}
                </div>
              )}
              {params?.data?.executives?.castingDirectors?.length > 0 && (
                <div>
                  <span>CD:</span>
                  {params?.data?.executives?.castingDirectors?.map((item, index, arr) => (
                    <span key={index} className="pl-1">
                      {item + (index !== arr.length - 1 ? ',' : '')}
                    </span>
                  ))}
                </div>
              )}
              {params?.data?.executives?.producers?.length > 0 && (
                <div>
                  <span>P:</span>
                  {params?.data?.executives?.producers?.map((item, index, arr) => (
                    <span key={index} className="pl-1">
                      {item + (index !== arr.length - 1 ? ',' : '')}
                    </span>
                  ))}
                </div>
              )}
            </div>
          );
        },
        width: 150,
        wrapText: true,
        autoHeight: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
      {
        headerName: 'Agents',
        field: 'agents',
        width: 140,
        wrapText: true,
        autoHeight: true,
        sortable: true,
        headerComponent: ColumnHeader,
        headerComponentParams: {
          onSortChanged,
          sortColumn,
          sortDirection: direction,
          showLoading,
          refreshGrid,
          updateSelectedCol: setIsSelected,
          currentSelectedCol: currentSelectedCol,
        },
      },
    ];
  };

  const [columnDefs, setColumDefs] = useState(columDefinations(isSelected, sortDirection));

  useEffect(() => {
    setColumDefs(columDefinations(isSelected, sortDirection));
  }, [isSelected, sortDirection]); // eslint-disable-line react-hooks/exhaustive-deps

  const sentinelRef = useRef(null);
  const [rowData, setRowData] = useState(null);

  useEffect(() => {
    const data = formatData(castings);
    
    setRowData(data);
  }, [castings]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (rowData?.length) {
      if (selectAll && Object.keys(selection).length === 0) {
        gridRef.current.api.selectAll();
      }
      if (selectAll && Object.keys(selection).length !== 0) {
        const selectedRows = Object.keys(selection);
        gridRef.current.api.forEachNode(node => {
          if (!selectedRows.includes(node.data.id)) node.setSelected(true);
        });
      }
    }
  }, [rowData]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (filterModel) {
      columnVisibility();
    }
  }, [filterModel.castingDateType, filterModel.sort]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (gridRef?.current) {
      if (gridRef.current.api) {
        gridRef.current.api.sizeColumnsToFit();
      }
    }
  }, [gridRef.current]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (gridRef.current && gridRef.current.api && !selectAll && Object.keys(selection).length === 0) {
      gridRef.current.api.deselectAll();
    }
  }, [selectAll, Object.keys(selection).length]); // eslint-disable-line react-hooks/exhaustive-deps

  const columnVisibility = useCallback(async () => {
    await gridRef.current;
    applyDateFilter();
    gridRef.current.api.sizeColumnsToFit();
  }, [filterModel]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (sentinelRef.current !== null) {
      const observer = new IntersectionObserver(entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting && castings && castings.length < maxCount && castings.length < resultCount) {
            onFetchMore();
          }
        });
      });
      observer.observe(sentinelRef.current);
      return () => {
        observer.disconnect();
      };
    }
  }, [onFetchMore, resultCount]); // eslint-disable-line react-hooks/exhaustive-deps

  const applyDateFilter = () => {
    if (filterModel.castingDateType) {
      const columnsList = dateFilterType(filterModel.castingDateType);
      const columnsToDisplay = defaultColumnsDisplay.filter(item => !columnsList.includes(item));
      const columnsToHide = defaultColumnsDisplay.filter(item => columnsList.includes(item));
      gridRef.current.api.setColumnsVisible(columnsToDisplay, true);
      gridRef.current.api.setColumnsVisible(columnsToHide, false);
    } else {
      gridRef.current.api.setColumnsVisible(defaultColumnsDisplay, true);
      gridRef.current.api.setColumnsVisible(defaultColumnsHidden, false);
    }
  };

  const dateFilterType = filter => {
    if (filter === 'Last Updated') return ['Start', 'Created'];
    if (filter === 'Created') return ['Updated', 'Start'];
    if (filter === 'Start Date') return ['Updated', 'Created'];
  };

  const onRowSelect = event => {
    const projectId = event.data.project.id;
    const selection = { [event.data.id]: projectId };
    onSelectedChanged(selection);
  };

  const onSelectAll = () => {
    const selectedRows = gridRef?.current?.api?.getSelectedRows();
    const totalRows = gridRef?.current?.api.getDisplayedRowCount();
    const isAll = selectedRows?.length === totalRows;
    const isNone = selectedRows?.length === 0;
    isAll ? onSelectAllChanged(isAll) : isNone && onSelectAllChanged(isAll);
  };

  const onCellClicked = event => {
    const projectId = event?.data?.project?.id;
    if (projectId) window.open(`/project/${projectId}`, '_blank', 'noopener,noreferrer');
  };

  const onGridReady = e => {
    e.api.sizeColumnsToFit();
  };

  return (
    <React.Fragment>
      <div className="d-flex align-items-center flex-wrap">
        <div className="col-12">
          <ActionButtons buttons={buttons} />
          <CastingBadges resetFilters={resetFilters} badges={filterBadges} />
        </div>
        <div className="ag-theme-alpine w-100">
          <AgGrid
            ref={gridRef}
            rowData={rowData}
            columnDefs={columnDefs}
            domLayout={'autoHeight'}
            gridOptions={{
              rowSelection: {
                headerCheckbox: true,
                checkboxes: true,
                hideDisabledCheckboxes: true,
                mode: 'multiRow',
                enableClickSelection: false,
              },
              suppressClickEdit: true,
              colResizeDefault: 'shift',
              suppressScrollOnNewData: true,
              suppressRowTransform: true,
              animateRows: false,
              accentedSort: false,
              cacheBlockSize: 1,
              maxBlocksInCache: 1,
              onNewColumnsLoaded: e => {
                e.api.sizeColumnsToFit();
              },
            }}
            rowBuffer={rowData ? rowData?.length : 100}
            debounceVerticalScrollbar={true}
            animateRows={false}
            icons={icons}
            onSelectionChanged={() => onSelectAll()}
            onRowSelected={event => onRowSelect(event)}
            onCellClicked={event => onCellClicked(event)}
            onGridReady={onGridReady}
            getRowId={getRowId}
            overlayLoadingTemplate={
              '<span class="ag-overlay-loading-center">Please wait while your rows are loading</span>'
            }
          />
          <div className="ais-InfiniteHits-sentinel" ref={sentinelRef} aria-hidden="true" />
          {castings?.length && PageCount(castings?.length, resultCount)}
          {castings?.length >= maxCount && (
            <div className="py-4" style={{ textAlign: 'center' }}>
              <p className="c-dialog">
                Showing the first {maxCount} results of {resultCount} matching your search.
              </p>
              <br />
              <p className="c-dialog">Please narrow your search to show all results.</p>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(Grid);

const formatData = castings => {
  const data = castings?.map(hit => ({
    id: hit?.objectID,
    active: hit?.active,
    name: hit?.role,
    ageMax: hit?.ageMax,
    ageMin: hit?.ageMin,
    gender: hit?.gender,
    project: { id: hit?.projectID, name: hit?.projectName, studios: hit?.studios?.map(item => item?.name) },
    role: hit?.role,
    roleType: `${hit?.type} ${hit?.status}`,
    Start: hit?.startedAt ? new Date(hit.startedAt).toLocaleDateString('en-US') : '',
    Updated: hit?.lastUpdatedAt ? new Date(hit.lastUpdatedAt).toLocaleDateString('en-US') : '',
    Created: hit?.createdAt ? new Date(hit.createdAt).toLocaleDateString('en-US') : '',
    talent: hit?.talent,
    executives: hit?.executives,
    agents: hit?.agents?.map(item => item.name),
    status: hit?.status,
    type: hit?.type,
    projectID: hit?.projectID,
  }));
  return data;
};

const icons = {
  sortAscending:
    '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg>',
  sortDescending:
    '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.8em" width="0.8em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg>',
  sortUnSort:
    '<div class="d-flex flex-column p-0"><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.6em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M858.9 689L530.5 308.2c-9.4-10.9-27.5-10.9-37 0L165.1 689c-12.2 14.2-1.2 35 18.5 35h656.8c19.7 0 30.7-20.8 18.5-35z"></path></svg><svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="0.6em" width="0.7em" xmlns="http://www.w3.org/2000/svg"><path d="M840.4 300H183.6c-19.7 0-30.7 20.8-18.5 35l328.4 380.8c9.4 10.9 27.5 10.9 37 0L858.9 335c12.2-14.2 1.2-35-18.5-35z"></path></svg></div>',
};

const defaultColumnsDisplay = [
  'Project',
  'Role',
  'Role Type',
  'Start',
  'Updated',
  'Created',
  'Talent',
  'Executives',
  'Agents',
];

const defaultColumnsHidden = ['Created', 'Updated'];

const ColumnHeader = col => {
  const columnName = col.column.colId === 'project' ? 'Project' : col.column.colId;
  let currentDirection = col.sortColumn === columnName ? col.sortDirection : null;
  const handleOnClick = direction => {
    col.showLoading();
    col.updateSelectedCol(columnName);
    currentDirection = direction;
    col.onSortChanged(columnName, direction);
    col.refreshGrid();
  };
  return <SortableColumnHeader text={col.displayName} direction={currentDirection} onClick={handleOnClick} />;
};

const PageCount = (currentProjectsAmount, totalProjects) => {
  return (
    <div className="float-right py-2">
      <span className="c-dialog-count">
        {currentProjectsAmount} / {totalProjects}
      </span>
    </div>
  );
};
