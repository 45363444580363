import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { styled } from 'react-free-style';
import classnames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';
import TextField from '@material-ui/core/TextField';
import { PersonInput } from '@united-talent-agency/julius-frontend-components';
import { createProjectNote, updateProjectNote } from '@united-talent-agency/julius-frontend-store';

import { DoneButtons } from '../../../../../../components/done-buttons/done-buttons';

import { getPeopleIndex } from '../../../../../../support/algolia/get-index';
import { searchClient } from '../../../../../../support/algolia/algolia-search-client';

const NOTE_STATUSES = [
  '--',
  'Submitted',
  'Interested',
  'Offer',
  'Buyer Passed',
  'Client Passed',
  'Confirmed',
  'Cancelled',
];

const ProjectNoteForm = ({ project, note, onDone, noteType, styles, isEdit }) => {
  const agentName = useSelector(state => state.desk && state.desk.agentId && state.user.desk.agentId.name);
  const userName = useSelector(state => state.user && `${state.user.first_name} ${state.user.last_name}`);
  const defaultUserId = useSelector(state => state.user && state.user.personId);
  const agentId = useSelector(state => state.user && state.desk && state.desk.agentId);
  const [name, setName] = useState(
    note && note.personId && note.personId.name ? note.personId.name : agentName ? agentName : userName
  );
  const [isSaving, setIsSaving] = useState(false);
  const [person, setPerson] = useState((note && note.personId) || agentId || defaultUserId);
  const [pertainingTo, setPertainingTo] = useState(note && note.pertainingToId ? note.pertainingToId.name : '');
  const [pertainingToPerson, setPertainingToPerson] = useState(note && note.pertainingToId ? note.pertainingToId : '');
  const [fee, setFee] = useState((note && note.fee) || '');
  const [status, setStatus] = useState((note && note.status) || '');
  const [results, setResults] = useState([]);
  const [internal, setInternal] = useState(noteType === 'Literary' ? note.internal : false);
  const [_note, setNote] = useState((note && note.note) || '');
  const dispatch = useDispatch();

  const projectNote = {
    projectId: project._id,
    personId: (person && person._id) || agentId,
    pertainingToId: pertainingToPerson && pertainingToPerson._id,
    note: _note,
    type: noteType,
    internal,
  };
  projectNote.fee = fee;
  projectNote.status = status;

  const _id = note && note._id;

  const onPertainingChange = async name => {
    const index = getPeopleIndex();
    const indexSetting = {
      filters: '(type: Client)',
      hitsPerPage: 10,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    const { hits: results } = await searchClient(index, name, indexSetting);
    const data = transformClientData(results);
    setResults(data);
  };

  const onNameChange = async name => {
    const index = getPeopleIndex();
    const indexSetting = {
      filters: '(type: Employee)',
      hitsPerPage: 10,
      page: 0,
      restrictSearchableAttributes: ['name'],
    };
    const { hits: results } = await searchClient(index, name, indexSetting);
    setResults(results);
  };

  const onStatusChange = status => {
    if (status !== 'Confirmed') {
      setFee('');
    }
  };

  const submit = async e => {
    e.preventDefault();
    setIsSaving(true);

    if (isEdit) {
      projectNote._id = _id;
      await dispatch(updateProjectNote(_id, projectNote));
    } else {
      await dispatch(createProjectNote(projectNote));
    }
    setIsSaving(false);
    onDone();
  };

  const transformClientData = clients => {
    // eslint-disable-next-line
    return clients.map(item => {
      if (item && !item.verified) return item;
      if (item && item.verified) {
        return { ...item, verifiedByName: `${item.verifiedBy?.first_name} ${item.verifiedBy?.last_name}` };
      }
    });
  };

  return (
    <form name='addNoteForm' onSubmit={e => submit(e)}>
      <div className={classnames(styles.inputRow, styles.section)}>
        <div className={styles.formField}>
          <PersonInput
            inline
            results={results}
            value={name}
            label="Agent"
            focus
            onChange={name => {
              setName(name);
              onNameChange(name);
            }}
            onSelect={person => {
              setPerson(person);
              setName(person.name);
              setResults(null);
            }}
          />
        </div>
        <div className={styles.formField}>
          <PersonInput
            inline
            results={results}
            title="Client"
            value={pertainingTo}
            focus
            notRequired={true}
            onChange={pertainingTo => {
              setPertainingTo(pertainingTo);
              onPertainingChange(pertainingTo);
              if (pertainingTo === '') {
                setPertainingToPerson(null);
              }
            }}
            onSelect={person => {
              setPertainingToPerson(person);
              setPertainingTo(person.name);
              setResults(null);
            }}
          />
        </div>
        <div className={classnames(styles.section, styles.nameInput)}>
          <TextField
            select
            variant="outlined"
            fullWidth
            label="Status"
            value={status}
            onChange={e => {
              setStatus(e.target.value);
              onStatusChange(e.target.value);
            }}
          >
            {NOTE_STATUSES.map((option, index) => (
              <MenuItem key={index} value={option}>
                {option}
              </MenuItem>
            ))}
          </TextField>
        </div>
      </div>
      <div className={styles.container}>
        <div className={classnames(styles.section, styles.textarea)}>
          <TextField
            inputProps={{ "data-testid": "description-input" }}
            multiline
            cols={10}
            fullWidth
            name="Note"
            label="Note"
            variant="outlined"
            value={_note}
            onChange={e => {
              setNote(e.target.value);
            }}
          />
        </div>
      </div>
      <div className={classnames(styles.inputRow, styles.section)} style={{ marginBottom: 20 }}>
        <div className={styles.formField} style={{ width: 100 }}>
          {noteType === 'Literary' && (
            <TextField
              select
              variant="outlined"
              fullWidth
              label="Internal"
              value={internal}
              onChange={e => {
                setInternal(e.target.value);
              }}
            >
              <MenuItem key={0} value={false}>
                No
              </MenuItem>
              <MenuItem key={1} value={true}>
                Yes
              </MenuItem>
            </TextField>
          )}
        </div>
        <div className={styles.formField} style={{ marginLeft: 'auto' }}>
          <DoneButtons
            isActive={true}
            isSaving={isSaving}
            onDone={() => {
              setIsSaving(false);
              onDone();
            }}
          />
        </div>
      </div>
    </form>
  );
};

const withStyles = styled({
  container: {
    marginBottom: 20,
    display: 'flex',
  },
  inputRow: {
    display: 'flex',
  },
  section: {
    marginTop: 2,
    marginBottom: 2,
    marginLeft: 6,
    marginRight: 6,
  },
  formField: {
    marginRight: '10px',
  },
  numberColumn: {
    marginLeft: 15,
  },
  feeInput: {
    textAlign: 'right',
  },
  nameInput: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.8,
  },
  dropDownInput: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.2,
  },
  selectInput: {
    display: 'flex',
    flexDirection: 'row',
    flex: 0.8,
    width: 200,
  },

  dropDownInputSmall: {
    display: 'flex',
    flexDirection: 'column',
    flex: 0.8,
  },
  textarea: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  bottomRow: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
});

export default withStyles(ProjectNoteForm);
