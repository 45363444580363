/*eslint-disable no-unsafe-optional-chaining */

import React, { useState, useEffect } from 'react';
import { helpers, styled } from 'react-free-style';
import { connect } from 'react-redux';
import { notify } from 'react-notify-toast';
import { loadTrackings, listHistoryProject } from '@united-talent-agency/julius-frontend-store';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { colors, icons, sizes } from '@united-talent-agency/julius-frontend-components';
import DataTableLoader from '../../../../../components/content-loader/content-loader';

const Trackings = ({ dispatch, projects, styles }) => {
  const [trackings, setTrackings] = useState([]);

  const transformChangesIntoTrackings = history => {
    const arr = [];

    for (const change of history) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      if ('AddedToTracking' in change?.delta) {
        let tracking = change.delta.AddedToTracking;
        arr.push({
          updatedAt: moment(new Date(change.timestamp)).toISOString(),
          status: 'Added',
          name: tracking?.name || tracking,
          _id: tracking?.id || undefined,
          updatedBy: change.userId?.first_name + ' ' + change.userId?.last_name,
        });
      }
      // eslint-disable-next-line no-unsafe-optional-chaining
      else if ('RemovedFromTracking' in change?.delta) {
        let tracking = change.delta.RemovedFromTracking;
        arr.push({
          updatedAt: moment(new Date(change.timestamp)).toISOString(),
          status: 'Removed',
          name: tracking?.name || tracking,
          _id: tracking?.id || undefined,
          updatedBy: change.userId?.first_name + ' ' + change.userId?.last_name,
        });
      }
    }
    return arr;
  };

  useEffect(() => {
    Promise.all([
      dispatch(listHistoryProject(projects.project._id)),
      dispatch(loadTrackings(null, null, projects.project._id)),
    ]).then(([historyResult, trackingsResult]) => {
      let combinedTrackings = [
        // eslint-disable-next-line no-unsafe-optional-chaining
        ...(trackingsResult ? trackingsResult.body?.map(t => ({ ...t, status: 'Added' })) : []),
        ...(historyResult
          ? transformChangesIntoTrackings(historyResult.body.data?.filter(el => el.objectType === 'project-trackings'))
          : []),
      ];
      combinedTrackings.sort((a, b) => {
        if (!a.updatedAt && !b.updatedAt) {
          return 0;
        } else if (!a.updatedAt) {
          return 1;
        } else if (!b.updatedAt) {
          return -1;
        } else {
          return new Date(b.updatedAt) - new Date(a.updatedAt);
        }
      });
      combinedTrackings.sort((a, b) => {
        if (!a.updatedBy && !b.updatedBy) {
          return 0;
        } else if (!a.updatedBy) {
          return 1;
        } else if (!b.updatedBy) {
          return -1;
        } else {
          return b.updatedBy - a.updatedBy;
        }
      });
      combinedTrackings = combinedTrackings.reduce((acum, cv) => {
        if (
          !acum.some(
            obj =>
              obj.status === cv.status &&
              obj.name === cv.name &&
              obj.updatedAt &&
              cv.updatedAt &&
              moment(obj.updatedAt).format('YYYY-MM-DD') === moment(cv.updatedAt).format('YYYY-MM-DD')
          )
        ) {
          acum.push(cv);
        }
        return acum;
      }, []);
      setTrackings(combinedTrackings);
    });
  }, [dispatch, projects.project._id]);

  if (!projects.project._id && trackings?.length === 0) {
    notify.show('Unable to load content, Please try later!', 'custom', 2000, {
      background: '#000000',
      text: '#FFFFFF',
    });
  }

  return (
    <div>
      <table id={'trackingTable'} className={styles.table}>
        <thead>
          <tr className={styles.th}>
            <th className={styles.thText}>Date</th>
            <th className={styles.thText}>List Name</th>
            <th className={styles.thText}>Status</th>
            <th className={styles.thText}>User</th>
          </tr>
        </thead>
        <tbody>
          {!projects.project._id && trackings?.length === 0 ? (
            <React.Fragment>
              <tr className={styles.td}>
                {Array.from(Array(2).keys()).map(item => {
                  return (
                    <td key={item}>
                      <DataTableLoader />
                    </td>
                  );
                })}
              </tr>
            </React.Fragment>
          ) : (
            <React.Fragment>
              {trackings.map((tracking, index) => {
                return (
                  <tr className={styles.td} key={index}>
                    <td className={styles.modified}>
                      {tracking.updatedAt ? moment(tracking.updatedAt).format('YYYY-MM-DD') : 'undefined'}
                    </td>
                    <td>
                      <span className={styles.name}>
                        {tracking?._id ? (
                          <Link
                            to={{ pathname: `/list/${tracking._id}` }}
                            target={tracking._id}
                            className={styles.link}
                          >
                            {tracking.name}
                          </Link>
                        ) : (
                          tracking.name
                        )}
                      </span>
                    </td>
                    <td>{tracking.status}</td>
                    <td>{tracking.updatedBy}</td>
                  </tr>
                );
              })}
            </React.Fragment>
          )}
        </tbody>
      </table>
    </div>
  );
};

const withStyles = styled({
  container: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: 20,
  },
  addContainer: helpers.merge(sizes.container, {
    paddingTop: 25,
    background: 'white',
    border: '1px black solid',
  }),
  table: {
    width: '100%',
    borderCollapse: 'collapse',
  },
  th: {
    color: colors.contentColor,
    fontSize: 12,
    textTransform: 'uppercase',
  },
  thText: {
    textAlign: 'left',
    padding: 10,
  },
  starActiveIcon: icons.starActive,
  divider: {
    height: 20,
  },
  pane: {
    background: colors.contentBackground,
  },
  loading: {
    textAlign: 'center',
    padding: 10,
  },
  menu: helpers.merge(
    {
      display: 'flex',
      marginBottom: 20,
    },
    sizes.container
  ),

  link: {
    cursor: 'pointer',
    color: '#000',
  },
  td: {
    '> td': {
      padding: 10,
      fontSize: 11,
      fontWeight: 300,
      color: colors.text,
    },
    '&:last-child': {
      borderBottom: `1px solid ${colors.border}`,
    },
    backgroundColor: colors.contentBackground,
    border: `1px solid ${colors.background}`,
    borderTopColor: colors.border,
    borderBottom: 0,
  },
  name: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    fontWeight: 'bold',
    textOverflow: 'ellipsis',
  },
});

const withState = connect(state => {
  const { user, projects } = state;

  return {
    user: user.user,
    projects: projects,
  };
});

export default React.memo(withState(withStyles(Trackings)));
